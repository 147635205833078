<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Deployment Dashboard</p>
                <p class="text-title mb-0">You don't have permission to view deployment dashboard</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 d-flex justify-space-between">
                    <h2 class="font-weight-regular">Deployment Dashboard</h2>
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }"><v-btn icon v-bind="attrs" v-on="on" :loading="loading" @click="get"><v-icon>mdi-refresh</v-icon></v-btn></template><span>Refresh</span></v-tooltip>
                </v-col>
                <v-layout align-center justify-center :style="getStyle()" v-if="loading">
                    <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
                </v-layout>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="!loading && items.length === 0">
                    <p class="text-title mb-0">There is no deployed shift yet</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="text-center" v-if="!loading && items.length > 0">
                    <v-sheet color="white" elevation="0">
                        <v-tabs show-arrows color="primary" v-model="tab">
                            <v-tab :key="index" v-text="getWeekText(item)" v-for="(item, index) in weeks"></v-tab>
                        </v-tabs>
                    </v-sheet>
                    <v-sheet color="white" elevation="0" class="pa-4 mt-6 pb-0">
                        <v-row>
                            <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="text-center">No. of Unfilled Headcount</v-col>
                            <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="text-center" :class="getEmptyShiftClass(object.empty_shifts)" v-text="object.empty_shifts"></v-col>
                            <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="text-center">No. of Filled Headcount</v-col>
                            <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="text-center" :class="getAssignedShiftClass(object.assigned_shifts, object.total_shifts)" v-text="object.assigned_shifts"></v-col>
                            <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="text-center">No. of Deployment</v-col>
                            <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="text-center" v-text="object.total_shifts"></v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"  v-if="!loading && items.length > 0">
                    <v-toolbar dense class="mb-3 pa-0 elevation-0" v-if="items.length > 0">
                        <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="toggleExpansionPanel('o')">Open All</v-btn>
                        <v-btn small color="primary" class="elevation-0" :ripple="false" @click="toggleExpansionPanel('c')">Close All</v-btn>
                    </v-toolbar>
                    <v-expansion-panels multiple v-model="panel">
                        <v-expansion-panel :key="index" v-for="(item, index) in items">
                            <v-expansion-panel-header>
                                <span v-text="getProjectTitleText(item)"></span>
                                <template v-slot:actions>
                                    <v-btn small color="primary" class="elevation-0" target="_blank" :to="getDeploymentUrl(item.id)" :ripple="false" v-if="item.id">View More</v-btn>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-sheet color="white" elevation="0" class="">
                                    <v-row>
                                        <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="text-left">No. of Unfilled Headcount</v-col>
                                        <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="text-center" :class="getEmptyShiftClass(item.empty_shifts)" v-text="item.empty_shifts"></v-col>
                                        <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="text-left">No. of Filled Headcount</v-col>
                                        <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="text-center" :class="getAssignedShiftClass(item.assigned_shifts, item.total_shifts)" v-text="item.assigned_shifts"></v-col>
                                        <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3" class="text-left">No. of Deployment</v-col>
                                        <v-col cols="12" xs="6" sm="6" md="1" lg="1" xl="1" class="text-center" v-text="item.total_shifts"></v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                            <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="item.shifts">
                                                <template v-slot:item='{ item }'>
                                                    <tr>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.work_date"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.work_day"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.outlet"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.start_time"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.end_time"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.promoter.rating"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.promoter.name"></td>
                                                        <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.promoter.mobile_number"></td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </template>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'

export default {
    name: 'DashboardDeployment',
    components: {
        messageNotifier: MessageNotifier,
    },
    data() {
        return {
            loading: true,
            tab: null,
            panel: [],
            filters: {
				work_date: '',
			},
            object: {
                total_shifts: '',
                assigned_shifts: '',
                empty_shifts: ''
            },
            permissions: {
                can_list: false,
            },
			weeks: [],
            items: [],
            headers: []
        }
    },
    computed: {
        notifier() {
            return this.$refs.notifier
        }
    },
    mounted: function() {
        delay(this.get, 1500)
    },
    methods: {
        get: function() {
            this.items = []
            this.$store.dispatch('epanel/dashboard/getDeploymentDashboard', this.filters).then((response) => {
                console.log(response)
                this.items = response.data.items
                this.headers = response.data.headers
                this.object = response.data.object
                this.weeks = response.data.weeks
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getStyle: function() {
            const height = this.$route.meta.has_bottom_navigation?2*56:56
            const minHeight = (window.innerHeight - height).toString() + "px"
            return {"min-height": minHeight}
        },
        getWeekText: function(item) {
            return item.name + "  (" + item.assigned_shifts + "/" + item.total_shifts + ")"
        },
        getProjectTitleText: function(item) {
            return item.project + " - " + item.campaign + "  (" + item.assigned_shifts + "/" + item.total_shifts + ")"
        },
        getAssignedShiftClass: function(assigned, total){
            return assigned === total?'green--text':''
        },
        getEmptyShiftClass: function(quantity) {
            return quantity > 0?'red--text':''
        },
        getDeploymentUrl: function(id) {
            return '/epanel/project/brand/' + id + '/deployment'
        },
        changeTab: function(tab) {
            this.filters.work_date = this.weeks[tab].work_date
            this.get()
        },
        toggleExpansionPanel: function(action) {
            if(action === 'c') {
                 this.panel = []
            }else if(action === 'o') {
                this.panel = [...Array(this.items).keys()].map((k, i) => i)
            }
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        }
    },
    watch: {
        tab: function(newValue, oldValue){
            if(Number.isInteger(oldValue)){
                this.changeTab(newValue)
            }
        }
    }
}
</script>


<style scoped>

/deep/ .v-toolbar__content {
    padding: 4px 0px !important;
}

</style>